body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #393939;
  margin: 0px;
  margin-bottom: 80px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #f5f5f5;
  font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
  max-width: 100vw;
}

.app-logo {
  height: 42px;
  margin-left: 10px;
  margin-top: 12px;
  margin-right: 10px;
}

.row {
  align-items: center;
  justify-content: center;
}

.row-center {
  align-items: center;
  justify-content: center;
}

.main-title-bar {
    display: flex;
    font-size: 32px;
    align-content: center;
    margin-left: 10px;
    margin-top: 10px;
    justify-content: center;
}

.main-title-bar h1 {
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: 10px;
}
.main-title-bar img {
    width: 5vw;
    height: 100%;
}

.blueButton {
  background-color:#43B8F7;
  border-radius:28px;
  border:1px solid #43B8F7;
  cursor:pointer;
  color:#ffffff;
  font-family: HelveticaNeue-Light;
  font-size:17px;
  padding:16px 31px;
  margin: 5px;
  marker-left: 10px;
  text-decoration:none;
}

.greenButton {
  background-color:#7FE683;
  border-radius:28px;
  border:1px solid #7FE683;
  cursor:pointer;
  color:#ffffff;
  font-family: HelveticaNeue-Light;
  font-size:17px;
  padding:16px 31px;
  margin: 5px;
  marker-left: 10px;
  text-decoration:none;
}

.blueButton:hover {
  background-color:#53BEF7;
}

.content-area {
    padding: 25px;
    margin: 25px;
    justify-content: center;
}

.live-chart {
  width: 80%;
  height: 20%;
}

.text-area {
    padding: 25px;
    margin: 25px;
    justify-content: center;
    align-items: center;
}

.flex-col{
    display: flex;
    direction: column;
    justify-content: center;
}

.flex-row {
    display:flex;
}

.main-page-container {
    display: flex;
    flex-direction: column;
}